import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import RegisterForm from '../Components/Regester';


const PrivateRoute = ({ children }) => {
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const { token } = useSelector((store) => store.AuthReducer);

    if (!token) {
        if (!showRegisterForm) {
            setShowRegisterForm(true);
            toast.info("Please register to access this page", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        return (
            <>
                <Navigate to='/' state={{ path: 'login' }} replace />
                {showRegisterForm && (
                    <RegisterForm show={showRegisterForm} handleClose={() => setShowRegisterForm(false)} />
                )}
            </>
        );
    }

    return children;
}

export default PrivateRoute;
