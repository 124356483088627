import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

const BetHistory = () => {
  const [Data, setData] = useState([]);
  const { token } = useSelector((store) => store.AuthReducer);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        'https://api.babadreamclub.com/api/betHistory',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('API Response Data:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]); // Update state to indicate an error occurred
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <main className="main1 pt-3">
        <div className="container">
          <div className="PagesHeading">
            <h2>Bet History</h2>
          </div>
          <div className="row">
            {Data.length > 0 ? (
              Data.map((item, index) =>
                item.bets.map((bet, betIndex) => {
                  const date = new Date(item.createdAt);
                  const formattedDate = isNaN(date.getTime())
                    ? 'Invalid Date'
                    : date.toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      });

                  // Ensure gameResult exists
                  const resultColor = bet.gameResult ? bet.gameResult.resultColor : '--';
                  const resultValue = bet.gameResult ? bet.gameResult.resultValue : '--';

                  return (
                    <div key={`${index}-${betIndex}`} className="col-md-4 mb-3">
                      <div className="card small-card">
                        <div className="card-body">
                          <div className="d-flex flex-column align-items-center ">
                            <div className="d-flex justify-content-center w-100 ">
                             
                              <div className="text-center ">
                                <strong>Game ID :  {bet.gameID}</strong>
                                
                              </div>
                             
                            </div>
                            <hr className='bg-dark w-100 h-4' />
                            <div className="d-flex justify-content-between w-100 mb-2">
                              <div className="text-center">
                                <strong>Bet Color : </strong> { bet.betColor}
                              </div>
                              <div className="text-center">
                                <strong>Result Color : </strong>
                                {resultColor}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between w-100 mb-2">
                              <div className="text-center">
                                <strong>Bet Value : </strong>
                                {bet.betValue}
                              </div>
                              <div className="text-center">
                                <strong>Result Value : </strong>
                                {resultValue}
                              </div>
                            </div>


                            <div className="d-flex justify-content-between w-100 mb-2">
                            <div className="text-center">
                              <strong>
                              {formattedDate}
                              </strong>
                            </div>
                            <div className="text-center mb-2">
                              <strong>Bet Amount : </strong>
                              {bet.betAmount > 0 ? bet.betAmount : '--'}
                            </div>
                           
                            </div>
                          
                            <div className="text-center mb-2">
                              <strong>
                              <p className={`fw-medium ${bet.status === 'won' ? 'text-success' : 'text-danger'}`}>
                                {bet.status}
                              </p>
                              </strong>
                            </div>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )
            ) : (
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <p>No Data Available</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default BetHistory;
