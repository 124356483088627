import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

function AccountStatementPage() {
    const [Data, setData] = useState([]);
    const { token } = useSelector((store) => store.AuthReducer);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                "https://api.babadreamclub.com/api/withdrawl-deposite-h",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log("API Response Data:", response.data);
            setData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            setData([]); // Update state to indicate an error occurred
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getStatusLabel = (type, status) => {
        if (type === "D" && status === "A") return "✅";
        if (type === "W" && status === "C") return "✅";
        if (status === "P") return "❌";
        return "Unknown"; // Fallback for unknown status
    };

    return (
        <>
            <main className="main1 pt-3">
                <div className="container">
                    <div className="PagesHeading">
                        <h2>Transaction</h2>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-bordered w-100">
                            <thead>
                                <tr>
                                    <th width="160px">Date</th>
                                    <th width="150px">Type</th>
                                    <th width="150px">Amount</th>
                                    <th width="150px">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Data &&
                                    Data.map((item, index) => {
                                        const date = new Date(item.created_at);
                                        if (isNaN(date.getTime())) {
                                            return (
                                                <tr key={index}>
                                                    <td colSpan="4">Invalid Date</td>
                                                </tr>
                                            );
                                        }

                                        return (
                                            <tr key={index}>
                                                <td className="text-nowrap">
                                                    {date.toLocaleString("en-US", {
                                                        day: "2-digit",
                                                        month: "short",
                                                        year: "numeric",
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                        hour12: true,
                                                    })}
                                                </td>
                                                <td>
                                                    <span className="fw-medium">
                                                        {item.type === "W" ? "Withdrawal" : "Deposit"}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="fw-medium text-success">
                                                        {item.amount > 0 ? item.amount : "--"}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="fw-medium text-success">
                                                        {getStatusLabel(item.type, item.status)}
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
        </>
    );
}

export default AccountStatementPage;
